<template>
	<div class="all">
		<div class="content">
			<div class="top g_center_bothSide">
				<div class="top_left g_center_left">
					<div class="top_item" @click="go('/')">
						<a href="/" target="_blank" >
						首页
						</a>
					</div>
					<div class="top_item" @click="go('/newhouse')">
						<a href="/newhouse" target="_blank" >
						新厂房
						</a>
					</div>
					<div class="top_item" @click="go('/usedlist')">
						<a href="/usedlist" target="_blank" >
						二手房
						</a>
					</div>
					<div class="top_item" @click="go('/leaselist')">
						<a href="/leaselist" target="_blank" >
						二手租赁
						</a>
					</div>
					<div class="top_item"  @click="go('/garden')">
						<a href="/garden" target="_blank" >
						云上园区
						</a>
					</div>
					<div class="top_item"  @click="go('/policy')">
						<a href="/policy" target="_blank" >
						政策通
						</a>
					</div>
					<div class="top_item" @click="go('/entrust')">
						<a href="/entrust" target="_blank" >
						在线委托
						</a>
					</div>
					<!-- <div class="top_item">
						网站地图
					</div>
					<div class="top_item">
						加入我们
					</div>
					<div class="top_item">
						关于我们
					</div> -->
				</div>
				<div class="top_right g_center">
					<img src="@/assets/phoneone.png" alt=""><span>客服热线 400-660-1258</span>
				</div>
			</div>
			<div class="c g_center_bothSide">
				<div class="c_left g_left_bothSide_v">
					<div class="city g_left_bothSide_v">
						<div class="g_center_left" v-if="pageType == 1">
							<div :class="type == 1?'title1':'title'" @mouseover="changes(1)">
								工业园区
							</div>
							<div :class="type == 2?'title1':'title'" @mouseover="changes(2)">
								厂房出售
							</div>
							<div :class="type == 3?'title1':'title'" @mouseover="changes(3)">
								厂房出租
							</div>
						</div>
						<div class="g_center_left" v-if="pageType == 2">
							<div :class="type == 1?'title1':'title'" @mouseover="changes(1)">
								工业园区
							</div>
						</div>
						<div class="g_center_left" v-if="pageType == 3">
							<div :class="type == 2?'title1':'title'" @mouseover="changes(2)">
								厂房出售
							</div>
							<div :class="type == 3?'title1':'title'" @mouseover="changes(3)">
								厂房出租
							</div>
						</div>
						<div class="c_item">
							<span v-for="(item,index) in citylist" :key="index"
								@click="clickCity(item,index)"><a href="" target="_blank" >{{item}}{{typeTitle}}</a></span>
						</div>
					</div>
					<!-- <div class="link  g_left_bothSide_v">
						<div class="title">
							友情链接
						</div>
						<div class="c_item1">
							<a href="###">厂房出租网</a>
							<a href="###">出租地坪漆</a>
							<a href="###">上海装修</a>
							<a href="###">上海厂房出租</a>
							<a href="###">楼视界</a>
						</div>
					</div> -->
				</div>
				<div class="c_right g_center_bothSide">
					<div class="code">
						<img src="@/assets/codes.jpg" alt="">
						<div>扫一扫</div>
						<div>快速出租/售厂房</div>
					</div>
					<div class="code">
						<img src="@/assets/cnng.png" alt="">
						<div>扫码关注</div>
						<div>厂牛牛公众号</div>
					</div>
				</div>
			</div>
			<div class="bom g_left_bothSide_v">
				<div class="address">
					地址：杭州市拱墅区侯圣街99号顺丰创新中心T2幢8楼
				</div>
				<div class="email">
					邮箱：changniuniu168@163.com
				</div>
			</div>
		</div>
		<div class="copyright g_center">
			杭州千园信息科技有限公司 Copyright © 2017-{{time}}
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备2022005340号-1</a>
		</div>
	</div>
</template>

<script>
	import {
		setStore
	} from '@/util/store'
	export default {
		props: {
			arealist: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		watch: {
			arealist: {
				handler(newV, oldV) {
					// do something, 可使用this
					// this.dialogTableVisible = newV
					console.log('改变了')
					console.log(newV)
					this.citylist = {}
					newV.map(res => {
						this.citylist[res.id] = res.name
					})
				},
				deep: true
			}
		},
		methods: {
			go(path) {
				let newUrl = this.$router.resolve({
					path: path
				});
				window.open(newUrl.href, "_blank");
			},
			clickCity(item, index) {
				console.log(item, index)
				if (this.pageType == 1) {
					setStore({
						name: 'location',
						content: {
							city: item,
							dictKey: index
						}
					});
					if (this.type == 1) {

						let newUrl = this.$router.resolve({
							path: `/newhouse`,
							query:{
								city:index
							}
						});

						window.open(newUrl.href, "_blank");
					}
					if (this.type == 2) {
						let newUrl = this.$router.resolve({
							path: `/usedlist`,
							query:{
								city:index
							}
						});

						window.open(newUrl.href, "_blank");
					}
					if (this.type == 3) {
						let newUrl = this.$router.resolve({
							path: `/leaselist`,
							query:{
								city:index
							}
						});

						window.open(newUrl.href, "_blank");
					}
				}

				if (this.pageType == 2) {

					if (this.type == 3) {
						let newUrl = this.$router.resolve({
							path: `/leaselist`,
							query: {
								city:index,
								id: index,
								name: item,
								type: 'address'
							}
						});

						window.open(newUrl.href, "_blank");
					} else {
						app.scrollIntoView()
						this.$emit('change', {
							item,
							index
						})
					}
				}

				if (this.pageType == 3) {

					if (this.type == 2) {
						let newUrl = this.$router.resolve({
							path: `/usedlist`,
							query: {
								city:index,
								id: index,
								name: item,
								type: 'address'
							}
						});

						window.open(newUrl.href, "_blank");
					} else {
						app.scrollIntoView()
						this.$emit('change', {
							item,
							index
						})
					}
				}

			},
			changes(e) {
				this.type = e
				if (e == 1) {
					this.typeTitle = '工业园区'
				}
				if (e == 2) {
					this.typeTitle = '厂房出售'
				}
				if (e == 3) {
					this.typeTitle = '厂房出租'
				}
			}
		},
		data() {
			return {
				citylist: {},
				type: 1,
				typeTitle: '工业园区',
				pageType: 1,
				time: '2022'
			}
		},
		async created() {
			this.citylist = await this.getDict('new_house_page_ending');
			let time = new Date();
			this.time = time.getFullYear();
			console.log(this.time)
			if (this.$route.name == 'newhouse' || this.$route.name == 'usedlist' || this.$route.name == 'leaselist' && this.arealist.length > 0) {
				this.citylist = {}
				this.arealist.map(res => {
					this.citylist[res.id] = res.name
				})
				// this.citylist = this.arealist
				console.log(this.arealist)
				console.log(this.citylist)
			}
			
			if (this.$route.name == 'home' || this.$route.name == 'home1') {
				this.pageType = 1
			}
			if (this.$route.name == 'newhouse' || this.$route.name == 'newhousedetails') {
				this.pageType = 2
				this.typeTitle = '工业园区'
			}
			if (this.$route.name == 'usedlist' || this.$route.name == 'useddetails' || this.$route.name == 'leaselist' ||
				this.$route.name == 'leasedetails') {
				this.typeTitle = '厂房出售'
				this.pageType = 3
			}
			
		}
	}
</script>
<style>body{padding: 0;margin: 0;}</style>
<style lang="scss" scoped>
	a{
		color: #ffffff;
		text-decoration: none;
	}
	.all {
		width: 100%;
		background: #17181a;

		.content {
			width: 1200px;
			height: 500px;
			margin: 0 auto;
			padding-top: 15px;

			.top {
				border-bottom: 1px solid #3a3a3c;
				padding: 25px 0;

				.top_left {
					width: 600px;
					flex-wrap: wrap;

					.top_item {
						cursor: pointer;
						font-size: 16px;
						font-weight: 250;
						line-height: 19px;
						letter-spacing: 0px;
						color: #FFFFFF;
						margin-right: 30px;
						margin-top: 21px;

						&:hover {
							color: #ffffff;
							cursor: pointer;
							text-decoration: underline;
						}
					}
				}

				.top_right {
					font-size: 20px;
					font-weight: 250;
					line-height: 24px;
					letter-spacing: 0px;
					color: #FF5E06;

					img {
						width: 21px;
						height: 21px;
					}
				}

			}

			.c {
				border-bottom: 1px solid #3a3a3c;
				padding: 25px 0;

				.c_left {
					flex: 1;

					.title {

						font-size: 14px;
						font-weight: 250;
						line-height: 17px;
						letter-spacing: 0px;
						color: #B4B5B8;
						padding: 2px 3px;
						background: #2f3031;
						margin-bottom: 13px;
						margin-right: 20px;
						cursor: pointer;
					}

					.title1 {
						@extend .title;
						background: #363b4a;
					}
				}

				.c_right {
					width: 230px;
					margin-left: 103px;

					.code {
						font-size: 12px;
						font-weight: 250;
						line-height: 14px;
						text-align: center;
						letter-spacing: 0px;
						color: #B4B5B8;
					}

					img {
						width: 98px;
						height: 98px;
						margin-bottom: 9px;
					}
				}

				.c_item {

					font-size: 12px;
					font-weight: 250;
					line-height: 14px;
					letter-spacing: 0px;
					color: #B4B5B8;
					text-align: left;
					margin-bottom: 26px;

					span {
						display: inline-block;
						padding: 3px 6px;

						&:hover {
							color: #ffffff;
							cursor: pointer;
							text-decoration: underline;
						}
					}

					a {
						color: #B4B5B8;
						display: inline-block;
						padding: 3px 6px;
						text-decoration: none;

						&:hover {
							color: #ffffff;
							text-decoration: underline;
						}
					}
				}

				.c_item1 {
					@extend .c_item;
					margin: 0;
				}
			}

			.bom {
				font-size: 12px;
				font-weight: 250;
				line-height: 14px;
				letter-spacing: 0px;
				color: #B4B5B8;
				padding-top: 10px;

				.address {
					cursor: pointer;
					margin-top: 9px;

					&:hover {
						text-decoration: underline;
						color: #ffffff;
					}
				}

				.email {
					margin-top: 9px;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
						color: #ffffff;
					}
				}
			}
		}

		.copyright {
			width: 100%;
			height: 40px;
			background: #0b0e12;
			font-size: 12px;
			font-weight: 250;
			line-height: 14px;
			letter-spacing: 0px;
			color: #B4B5B8;

			a {
				text-decoration: none;
				color: #B4B5B8;
				margin-left: 10px;

				// &:hover {
				// 	text-decoration: underline;
				// }
			}
		}
	}
</style>