import {
	createApp,
	render
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MetaInfo from 'vue-meta-info'
import ElementUI from "element-plus";
import global from '../common/global';
import Cookies from 'js-cookie'
import {
	setStore,
	getStore
} from '@/util/store'
import {
	getDict,
	screenTime
} from '@/util/general'
import "element-plus/dist/index.css";
import {
	getDictionaryArray
} from '@/api/index'
import {
	validateminus
} from '@/util/validate'

const app = createApp(App);
app.config.globalProperties.$global = global;
app.use(ElementUI);
app.use(MetaInfo);
app.use(store);
app.use(router);
app.config.globalProperties.$imgUrl = "http://myzcoss.oss-cn-hangzhou.aliyuncs.com/";
app.config.globalProperties.getDict = getDict;
app.config.globalProperties.screenTime = screenTime; //时间格式化
app.config.globalProperties.$setStore = setStore;
app.config.globalProperties.$getStore = getStore;
app.config.globalProperties.validateminus = validateminus;
app.mount('#app')
// async function getDictall() {

// 	let data = getStore({
// 		name: 'parks_industry'
// 	});
// 	if (data) {
// 		app.mount('#app')
// 	} else {
// 		let params = {
// 			codes: ['parks_industry', 'agent_avatar', 'new_hourse_avrprce',
// 				'second_area', 'policy_categories', 'category_type', 'policy_hot_search',
// 				'policy_classification', 'policy_level',
// 				'new_horse_structure', 'guard_type', 'renovation_status','new_house_page_ending'
// 			].join(',')
// 		}
// 		let data = await getDictionaryArray(params);

// 		if (data.code == 200) {
// 			let obj = data.data
// 			for (let key in obj) {
// 				// console.log(key);
// 				let disposedata = {};
// 				obj[key].map(r => {
// 					disposedata[r.dictKey] = r.dictValue
// 				})
// 				setStore({
// 					name: key,
// 					content: JSON.stringify(disposedata)
// 				});
// 			}
// 			app.mount('#app')
// 		}
// 	}
// }
// getDictall();