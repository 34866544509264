<template>
	<div class="information">
		<a :href="`/policydetails?id=${item.id}`" target="_blank" v-for="(item,index) in datas" :key="index">
		<div class="information_item">
			<div class="i_title g_center"><span class="dian"></span> <span>{{item.title}}</span></div>
			<span>{{item.writtenTime}}</span>
		</div>
		</a>
		<el-empty v-if="datas && datas.length == 0"  />
		<!-- <div v-if="datas && datas.length == 0">{{desription}}</div> -->
	</div>
</template>

<script>
	export default {
		props: {
			datas: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data(){
			return {
				desription:"δ�ҵ�����,����λ�����԰�"
			}
		},
		methods: {
			goDetails(e) {
				let newUrl = this.$router.resolve({
					path: "/policydetails",
					query: {
						id: e
					}
				});
				window.open(newUrl.href, "_blank");
			}
		}
	}
</script>

<style lang="scss" scoped>
	a{
		text-decoration: none;
	}
	.information {
		width: 1200px;
		min-height: 470px;
		// opacity: 1;
		background: #FFFFFF;

		margin-top: 20px;

		.information_item {
			width: 1148px;
			padding: 0 26px;
			font-size: 14px;
			font-weight: normal;
			line-height: 17px;
			letter-spacing: 0px;
			color: #333333;
			height: 60px;
			border-bottom: 1px solid #f0f4f7;
			display: flex;
			justify-content: space-between;
			align-items: center;

			&:last-of-type {
				border-bottom: none;
			}
			transition: all 0.5s;
			&:hover {
				background: #f3f4f4;
				cursor: pointer;
			}

			.i_title {

				.dian {
					font-size: 28px;
					font-weight: normal;
					line-height: 34px;
					text-align: center;
					letter-spacing: 0px;
					color: #CBD0D4;
					margin-right: 10px;
				}
			}
		}
	}
</style>