import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/HomeView.vue')
	},
	{
		path: '/AboutView',
		name: 'AboutView',
		component: () => import('../views/AboutView.vue')
	},
	{
		path: '/usedlist',
		name: 'usedlist',
		component: () => import('../views/usedlist.vue')
	},
	{
		path: '/newhouse',
		name: 'newhouse',
		component: () => import('../views/newhouse.vue')
	},
	{
		path: '/useddetails',
		name: 'useddetails',
		component: () => import('../views/useddetails.vue')
	},
	{
		path: '/newhousedetails',
		name: 'newhousedetails',
		component: () => import('../views/newhousedetails.vue')
	},
	{
		path: '/entrust',
		name: 'entrust',
		component: () => import('../views/entrust.vue'),
		prop:true
	},
	{
		path: '/personaldetails',
		name: 'personaldetails',
		component: () => import('../views/personaldetails.vue')
	},
	{
		path: '/leaselist',
		name: 'leaselist',
		component: () => import('../views/leaselist.vue')
	},
	{
		path: '/leasedetails',
		name: 'leasedetails',
		component: () => import('../views/leasedetails.vue')
	},
	{
		path: '/policy',
		name: 'policy',
		component: () => import('../views/policy.vue')
	},
	{
		path: '/policylist',
		name: 'policylist',
		component: () => import('../views/policylist.vue')
	},
	{
		path: '/policydetails',
		name: 'policydetails',
		component: () => import('../views/policydetails.vue')
	},
	{
		path: '/garden',
		name: 'garden',
		component: () => import('../views/garden.vue')
	},
	{
		path: '/gardenlist',
		name: 'gardenlist',
		component: () => import('../views/gardenlist.vue')
	},
	{
		path: '/gardendetails',
		name: 'gardendetails',
		component: () => import('../views/gardendetails.vue')
	},
	


]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router