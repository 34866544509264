<template>
	<el-dialog v-model="dialogTableVisible" title=" " width="370" append-to-body @close="change">
		<div class="tem g_c_evenly_v">
			<img v-if="type == 0" :src="item.avatar?domain.img +item.avatar:getDict('agent_avatar')['agent_man']" alt="">
			<!-- <img v-if="type == 1" src="../assets/logosmall.png" alt=""> -->
			<div class="name">
				{{type == 1?'平台电话':item.name}}
			</div>
			<div class="experience" v-if="type == 0">
				2 年经验 | 服务客户 728 次
			</div>
			<div class="phone">
				<span class="iconfont icon-dianhua"></span>{{type == 1?'400-660-1258':item.phone}}
			</div>
			<!-- <div class="times">
				· 此号码 282 秒内拨打有效，超时请刷新
			</div> -->
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
				default: () => {
					return {}
				}
			},
			//0全部
			//1平台
			//2保留
			type:0
		},
		data(){
			return {
				dialogTableVisible: true,
				domain: {
					img: "http://img.myzc88.com",
					ossimg: "http://myzcoss.oss-cn-hangzhou.aliyuncs.com/",
				},
			}
		},
		methods:{
			change(){
				console.log("关闭")
				this.$emit('change','');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tem{
		width: 100%;
		img{
			width: 100px;
			height: 100px;
			border-radius: 50%;
		}
		.name{
			font-size: 24px;
			font-weight: 900;
			text-align: center;
			letter-spacing: 0em;
			color: #3D3D3D;
			padding-top: 8px;
		}
		.experience{
			padding-top: 15px;
			font-size: 18px;
			text-align: center;
			color: #666666;
		}
		.phone{
			font-size: 32px;
			color: #FF5E06;
			margin-top: 44px;
			span{
				font-size: 32px;
			}
		}
		.times{
			margin-top: 17px;
			font-family: Source Han Sans CN;
			font-size: 16px;
			font-weight: normal;
			text-align: center;
			letter-spacing: 0em;
			color: #8F98A3;
		}
	}
</style>