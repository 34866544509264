import request from "@/common/request";  //导入封装请求的js文件


//获取二手房列表
export function getSecondHourseList(params) {
  return request({
    url:'/api/secondHourse/open/secondHourseList', //"/api/newHourse/getNewHourseList",  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取二手房详情
export function getSecondHourse(params) {
  return request({
    url:'/api/secondHourse/open/details', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取展位详细
export function orderPromotion(params) {
  return request({
    url:'/api/open/orderPromotion/display/web/user', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}
