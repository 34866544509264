import {
	setToken,
	setRefreshToken,
	removeToken,
	removeRefreshToken
} from '@/util/auth'
import {
	setStore,
	getStore,
	removeStore
} from '@/util/store'
import {
	isURL,
	validatenull
} from '@/util/validate'
import {
	login,
	refreshToken,
	getUserInfo
} from '@/api/index'

const user = {
	state: {
		userInfo: getStore({
			name: 'userInfo'
		}) || [],
		token: getStore({
			name: 'token'
		}) || '',
		refreshToken: getStore({
			name: 'refreshToken'
		}) || ''
	},
	actions: {
		//根据手机号登录
		LoginByPhone({
			commit
		}, userInfo) {
			return new Promise((resolve) => {
				// console.log(userInfo);
				login(userInfo).then(res => {
					const data = res.data;
					commit('SET_TOKEN', data.accessToken);
					commit('SET_REFRESH_TOKEN', data.refreshToken);
					resolve();
				}).catch(err =>{
					console.log(err)
				})
			})
		},
		//获取用户信息
		GetUserInfo({
			commit
		}) {
			return new Promise((resolve, reject) => {
				getUserInfo().then((res) => {
					// console.log(res,"用户信息")
					const data = res.data;
					// console.log(data,"用户信息")
					commit('SET_USER_INFO', data);
					resolve(data);
				}).catch(err => {
					reject(err);
				})
			})
		},
		//刷新token
		refreshToken({
			state,
			commit
		}, userInfo) {
			return new Promise((resolve, reject) => {
				refreshToken().then(res => {
					const data = res.data;
					commit('SET_TOKEN', data.accessToken);
					commit('SET_REFRESH_TOKEN', data.refreshToken);
					resolve();
				}).catch(error => {
					reject(error)
				})
			})
		},
		//退出登录
		delUserInfo({
			state,
			commit
		}) {
			return new Promise((resolve, reject) => {
				state.userInfo = undefined;
				removeStore({name:'userInfo'});
				removeToken();
				removeRefreshToken();
				resolve({code:200});
			})
		},
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			setToken(token);
			state.token = token;
			setStore({
				name: 'token',
				content: state.token
			})
		},
		SET_REFRESH_TOKEN: (state, refreshToken) => {
			setRefreshToken(refreshToken)
			state.refreshToken = refreshToken;
			setStore({
				name: 'refreshToken',
				content: state.refreshToken
			})
		},
		SET_USER_INFO: (state, userInfo) => {
			state.userInfo = userInfo;
			setStore({
				name: 'userInfo',
				content: state.userInfo
			})
		}
	}

}
export default user