import request from "@/common/request";  //导入封装请求的js文件


//获取新房列表
export function getnewhourselist(params) {
  return request({
    url:'/api/newHourse/open/getNewHourseList', //"/api/newHourse/getNewHourseList",  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取新房详情
export function getnewhoursedetail(params) {
  return request({
    url:'/api/newHourse/open/getNewHourseDetail', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}
