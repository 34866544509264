<template>
	<div class="all g_left_bothSide_v">
		<div class="title">
			热门新园区
		</div>
		<div class="swper">
			<el-carousel :interval="5000" arrow="always" indicator-position="none">
				<el-carousel-item v-for="(i,x) in list" :key="x">
					<div class="card" v-for="(item,index) in i" :key="index">
						<a :href="item.targetUrl" target="_blank">
							<div class="block">
								<!-- src="https://img1.baidu.com/it/u=4049022245,514596079&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500" -->
								<el-image class="dade_sf" style="width: 424px; height: 288px"
									:src="this.$imgUrl + item.imageUrl">
									<template #error>
										<div class="image-slot g_center">
											<img src="@/assets/logo2.png" alt="">
										</div>
									</template>
								</el-image>
							</div>
							<div class="bom_t">
								<div class="t g_left_center_v">
									<span>{{item.titleOne}}</span>
									<span>{{item.titleTwo}}</span>
								</div>
								<div class="add">
									<!-- {{item.adDescribe}} -->
									<div v-html="item.adsName"></div>
								</div>
							</div>
						</a>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>

</template>
<script>
	import {
		getAds
	} from '@/api/index'
	export default {
		data() {
			return {
				list: []
			}
		},
		methods: {
			getList() {
				getAds(31).then(res => {
					// console.log(res.data)
					this.list = this.group(res.data, 2);
				})
			},
			group(array, subGroupLength) {
				let index = 0;
				let newArray = [];
				while (index < array.length) {
					newArray.push(array.slice(index, index += subGroupLength));
				}
				return newArray;
			}
		},
		created() {
			this.getList();
		}
	}
</script>
<style lang="scss" scoped>
	.all {
		width: 870px;
		// height: 430px;
		// border: 1px solid red;

		.title {
			
			font-size: 24px;
			font-weight: 600;
			line-height: 29px;
			letter-spacing: 0px;
			color: #333333;
			// margin-bottom: 23px;
		}

		.swper {
			width: 100%;
			min-height: 430px;

			.card {

				// height: 430px;
				a {
					text-decoration: none;
				}

				.bom_t {
					width: 418px;
					// min-height: 142px;
					padding: 3px;

					.t {
						width: 418px;
						
						font-size: 14px;
						font-weight: normal;
						line-height: 17px;
						letter-spacing: 0px;
						color: #333333;

						span {
							width: 418px;
							text-align: left;
							display: block;
							margin-top: 5px;
						}

						p {
							padding: 0;
							margin: 0;
						}
					}

					.add {
						font-size: 20px;
						font-weight: 400;
						line-height: 24px;
						letter-spacing: 0px;
						color: #FF5E06;
						text-align: left;
						margin-top: 14px;
					}
				}

				.block {
					width: 424px;
					height: 288px;
					// background: #000;
					position: relative;
					overflow: hidden;

					.el-image {
						transition: all 0.6s;
					}

					.el-image:hover {
						transform: scale(1.1);
					}

					.img_title {
						width: 57px;
						height: 25px;
						opacity: 1;
						background: #FF5E06;
						position: absolute;
						top: 19px;
						left: -3px;
						font-size: 12px;
						font-weight: 250;
						line-height: 14px;
						letter-spacing: 0px;

						color: #FFFFFF;
					}
				}
			}
		}
	}

	.el-carousel {
		min-height: 430px;

		& ::v-deep .el-carousel__arrow--right {
			right: 0;
			width: 32px;
			height: 70px;
			border-radius: 0px 2px 2px 0px;
			opacity: 0.6;
			background: #000000;
			color: #ffffff;
			font-size: 20px;
		}

		& ::v-deep .el-carousel__arrow--left {
			left: 0;
			width: 32px;
			height: 70px;
			border-radius: 0px 2px 2px 0px;
			opacity: 0.6;
			background: #000000;
			color: #ffffff;
			font-size: 20px;
		}
	}

	.el-carousel__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 430px;
	}
</style>