// import Cookies from 'js-cookie'
import {
	diclist
} from '@/api/index'
import {
	setStore,
	getStore
} from '@/util/store'

//获取字典原始列表
export function getDict(name) {

	let data = getStore({
		name: name
	})
	if (data) {
		// console.log(JSON.parse(data));
		return JSON.parse(data);
	} else {
		diclist({
			code: name
		}).then(res => {
			if (res.code == 200) {
				let disposedata = {};
				console.log(res.data);
				res.data.map(r => {
					disposedata[r.dictKey] = r.dictValue
				})
				console.log(disposedata);
				setStore({
					name: name,
					content: JSON.stringify(disposedata)
				});
				return disposedata
			}
		})
	}
}

Date.prototype.format1 = function(fmt) {
	let o = {
		'M+': this.getMonth() + 1, // 月份
		'd+': this.getDate(), // 日
		'h+': this.getHours(), // 小时
		'm+': this.getMinutes(), // 分
		's+': this.getSeconds(), // 秒
		'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
		S: this.getMilliseconds() // 毫秒
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(
			RegExp.$1,
			(this.getFullYear() + '').substr(4 - RegExp.$1.length)
		)
	}
	for (let k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
			)
		}
	}
	return fmt
}

function formatterDate(date, pattern) {
	let d = new Date(date).format1('yyyy-MM-dd hh:mm:ss') // 默认日期时间格式 yyyy-MM-dd hh:mm:ss
	if (pattern) {
		d = new Date(date).format1(pattern)
	}
	return d.toLocaleString()
}

function getDate(type) {
	const date = new Date();

	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();

	month = month > 9 ? month : '0' + month;;
	day = day > 9 ? day : '0' + day;
	if (type && type == '.') {
		return `${month}.${day}`;
	} else {
		if (type && type == 'yyyy') {
			return `${year}.${month}.${day}`;
		} else {
			return `${year}-${month}-${day}`;
		}

	}

}

//获取时间
export function screenTime(e) {
	let oneDayTime = 24 * 60 * 60 * 1000;
	let todayTime = new Date().getTime();
	let month = parseInt(new Date().getMonth() + 1);
	let year = new Date().getFullYear();
	let day = new Date().getDate();
	switch (e) {
		//今天
		case 0:
			return [(getDate() + ' 00:00:00'), (getDate() + ' 23:59:59'), getDate()]
			break;
			//明天
		case 1:
			let today = todayTime + oneDayTime
			return [(formatterDate(today, 'yyyy-MM-dd') + ' 00:00:00'), (formatterDate(today, 'yyyy-MM-dd') + ' 23:59:59')]
			break;
			// 本周
		case 2:
			let day1 = new Date().getDay();
			let SundayTime = todayTime + (7 - day1) * oneDayTime;
			return [(getDate() + ' 00:00:00'), (formatterDate(SundayTime, 'yyyy-MM-dd') +' 23:59:59')]
			break;
			// 最近7天
		case 3:
			let today1 = todayTime - 7 * oneDayTime
			return [(formatterDate(today1, 'yyyy-MM-dd') + ' 00:00:00'),(getDate() + ' 23:59:59')]
			break;
			// 最近30天
		case 4:
			let today2 = todayTime - 30 * oneDayTime
			return [(formatterDate(today2, 'yyyy-MM-dd') + ' 00:00:00'), getDate() + ' 23:59:59']
			break;
			// 最近6个月
		case 5:
			let time = formatterDate(todayTime - (181 * oneDayTime), 'yyyy-MM-dd');
			return [(time + ' 00:00:00'), (getDate() + ' 23:59:59')]
			break;
			// 最近一年
		case 6:
			year = year - 1;
			var myDate = formatterDate(new Date(year, month - 1, day));
			return [(myDate), (getDate() + ' 23:59:59')]
			break;
		default:
			break;
	}
}
//防抖
export function debounce(func, wait, options) {
    let lastArgs,
        lastThis,
        maxWait,
        result,
        timerId,
        lastCallTime

    // 参数初始化
    let lastInvokeTime = 0 // func 上一次执行的时间
    let leading = false
    let maxing = false
    let trailing = true

    // 基本的类型判断和处理
    if (typeof func != 'function') {
        throw new TypeError('Expected a function')
    }
    wait = +wait || 0
    // if (isObject(options)) {
    //     // 对配置的一些初始化
    // }

    function invokeFunc(time) {
        const args = lastArgs
        const thisArg = lastThis

        lastArgs = lastThis = undefined
        lastInvokeTime = time
        result = func.apply(thisArg, args)
        return result
    }

    function leadingEdge(time) {
        // Reset any `maxWait` timer.
        lastInvokeTime = time
        // 为 trailing edge 触发函数调用设定定时器
        timerId = setTimeout(timerExpired, wait)
        // leading = true 执行函数
        return leading ? invokeFunc(time) : result
    }

   function remainingWait(time) {
        const timeSinceLastCall = time - lastCallTime // 距离上次debounced函数被调用的时间
        const timeSinceLastInvoke = time - lastInvokeTime // 距离上次函数被执行的时间
        const timeWaiting = wait - timeSinceLastCall // 用 wait 减去 timeSinceLastCall 计算出下一次trailing的位置

        // 两种情况
        // 有maxing:比较出下一次maxing和下一次trailing的最小值，作为下一次函数要执行的时间
        // 无maxing：在下一次trailing时执行 timerExpired
        return maxing
            ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
            : timeWaiting
    }

    // 根据时间判断 func 能否被执行
    function shouldInvoke(time) {
        const timeSinceLastCall = time - lastCallTime
        const timeSinceLastInvoke = time - lastInvokeTime

        // 几种满足条件的情况
        return (lastCallTime === undefined //首次
            || (timeSinceLastCall >= wait) // 距离上次被调用已经超过 wait
            || (timeSinceLastCall < 0) //系统时间倒退
            || (maxing && timeSinceLastInvoke >= maxWait)) //超过最大等待时间
    }

    function timerExpired() {
        const time = Date.now()
        // 在 trailing edge 且时间符合条件时，调用 trailingEdge函数，否则重启定时器
        if (shouldInvoke(time)) {
            return trailingEdge(time)
        }
        // 重启定时器，保证下一次时延的末尾触发
        timerId = setTimeout(timerExpired, remainingWait(time))
    }

    function trailingEdge(time) {
        timerId = undefined

        // 有lastArgs才执行，意味着只有 func 已经被 debounced 过一次以后才会在 trailing edge 执行
        if (trailing && lastArgs) {
            return invokeFunc(time)
        }
        // 每次 trailingEdge 都会清除 lastArgs 和 lastThis，目的是避免最后一次函数被执行了两次
        // 举个例子：最后一次函数执行的时候，可能恰巧是前一次的 trailing edge，函数被调用，而这个函数又需要在自己时延的 trailing edge 触发，导致触发多次
        lastArgs = lastThis = undefined
        return result
    }

    function cancel() {}

    function flush() {}

    function pending() {}

    function debounced(...args) {
        const time = Date.now()
        const isInvoking = shouldInvoke(time) //是否满足时间条件

        lastArgs = args
        lastThis = this
        lastCallTime = time  //函数被调用的时间

        if (isInvoking) {
            if (timerId === undefined) { // 无timerId的情况有两种：1.首次调用 2.trailingEdge执行过函数
                return leadingEdge(lastCallTime)
            }
            if (maxing) {
                // Handle invocations in a tight loop.
                timerId = setTimeout(timerExpired, wait)
                return invokeFunc(lastCallTime)
            }
        }
        // 负责一种case：trailing 为 true 的情况下，在前一个 wait 的 trailingEdge 已经执行了函数；
        // 而这次函数被调用时 shouldInvoke 不满足条件，因此要设置定时器，在本次的 trailingEdge 保证函数被执行
        if (timerId === undefined) {
            timerId = setTimeout(timerExpired, wait)
        }
        return result
    }
    debounced.cancel = cancel
    debounced.flush = flush
    debounced.pending = pending
    return debounced
}