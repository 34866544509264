<template>
	<view class="">
		<a class="all" :href="`/gardendetails?id=${item.id}`" target="_blank">
			<div class="garden_item">
				<div class="garden_item_top">
					{{item.name}}
				</div>
				<div class="garden_item_c g_center_left" v-if="item.industry">
					<div class="g_center_left" v-for="(i,index) in item.industry.split(',')" :key="index">
						<span v-if="index < 4">{{i?dict[i]:''}}</span>
					</div>
					<div class="g_center_left"><span>···</span></div>
				</div>
				<div class="garden_item_bom g_center_bothSide">
					<div class="b_left g_center_bothSide">
						<!-- <img src="@/assets/address.png" alt=""> -->
						<span class="iconfont icon-location"></span>
						<span>
							{{item.provinceName}}-{{item.cityName}}-{{item.areaName}}
						</span>
					</div>
					<div class="bom_c g_center_bothSide_v">
						<div class="g_center">
							<span class="price">{{item.listingPrice}}</span>
						</div>
						<div class="">
							均价(元/m²)
						</div>
					</div>
				</div>
			</div>
		</a>
	</view>
</template>

<script>
	export default {
		props: {
			item: Object
		},
		data() {
			return {
				dict: {}
			}
		},
		created() {
			this.dict = this.getDict('parks_industry');
		},
		methods: {
			goDetails(e) {
				let newUrl = this.$router.resolve({
					path: `/gardendetails`,
					query: {
						id: e
					}
				});
				window.open(newUrl.href, "_blank");
			},
		}
	}
</script>

<style lang="scss" scoped>
	a:hover{
		text-decoration: none;
	}
	.garden_item {
		width: 354px;
		min-height: 132px;
		padding: 24px 18px 0 18px;
		opacity: 1;
		background: #FFFFFF;
		margin-top: 20px;
		cursor: pointer;
		transition: all 0.2s;



		.garden_item_top {
			text-align: left;
			width: 100%;
			font-size: 18px;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: 0px;
			color: #333333;
		}

		.garden_item_c {
			width: 100%;
			margin-top: 14px;

			span {
				display: inline-block;
				box-sizing: border-box;
				border: 1px solid #2A46F7;
				font-size: 12px;
				font-weight: 250;
				line-height: 14px;
				letter-spacing: 0px;
				color: #2A46F7;
				padding: 2px 5px;
				margin-right: 5px;
			}
		}

		.garden_item_bom {
			width: 100%;
			font-size: 12px;
			font-weight: 250;
			line-height: 14px;
			letter-spacing: 0px;
			color: #333333;
			margin-top: 40px;
			padding-bottom: 25px;

			.b_left {
				font-size: 12px;
				font-weight: 250;
				line-height: 14px;
				letter-spacing: 0px;
				color: #333333;

				span {
					&:first-child {
						color: #aaaaab;
						font-size: 10px;
					}
				}
			}

			.price {
				font-size: 16px;
				font-weight: 250;
				line-height: 19px;
				letter-spacing: 0px;
				color: #FF5E06;
				
			}

			.bom_c {
				height: 38px;
			}
		}

		&:hover {
			// transition: all 0.2s;
			box-shadow: 0px 2px 10px 0px rgba(71, 75, 78, 0.16);
			transform: scale(1.01);
			text-decoration: none;
			

			.garden_item_top {
				transition: all 0.3s;
				// text-decoration: underline;
				font-weight: 600;
			}
		}
	}

	

	// a:hover {
	// 	text-decoration: none;
	// }
</style>