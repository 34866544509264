<template>
	<div >
		<router-view>
		</router-view>
	</div>
</template>

<script>
	import {
		setStore
	} from '@/util/store'
	import {
		getDictionaryArray
	} from '@/api/index'
	
	export default {
		async created() {
			// console.log('初始化')
			let params = {
				codes: ['parks_industry', 'agent_avatar', 'new_hourse_avrprce',
					'second_area', 'policy_categories', 'category_type', 'policy_hot_search','parks_hot_search',
					'policy_classification', 'policy_level','parks_level','parks_carrier','parks_type',
					'new_horse_structure', 'guard_type', 'renovation_status','new_house_page_ending'
				].join(',')
			}
			let data = await getDictionaryArray(params);
			
			if (data.code == 200) {
				let obj = data.data
				for (let key in obj) {
					// console.log(key);
					let disposedata = {};
					obj[key].map(r => {
						disposedata[r.dictKey] = r.dictValue
					})
					setStore({
						name: key,
						content: JSON.stringify(disposedata)
					});
				}
				// app.mount('#app')
			}
		}
	}
</script>
<style>
	@import './assets/all.css';
	@import './assets/fontIcon/iconfont.css';
	body{
		padding: 0;
		margin: 0;
	}
	#app {
		padding: 0;
		margin: 0;
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	nav {
		padding: 30px;
	}

	nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	nav a.router-link-exact-active {
		color: #42b983;
	}

	#app {
		font-family: "微软雅黑";
	}
	a {
		text-decoration: none;
	}
</style>