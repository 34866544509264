<template>
	<div>
		<div class="home">
			<div id="bg">
				<navbar @citychange="hanldheadercity" />
				<div id="site">
					<div class="site_title">
						<strong>企业选址 就找厂牛牛</strong>
					</div>
					<div class="navlist g_center_left">
						<div class="item g_center" @click="changeType(0)">
							<img src="../assets/sy_tab_icon_zufang@2x.png" alt />
							<span>租厂房</span>
						</div>
						<div class="item g_center" @click="changeType(1)">
							<img src="../assets/sy_tab_icon_changfang@2x备份.png" alt />
							<span>买厂房</span>
						</div>
						<div class="item g_center" @click="changeType('/entrust')">
							<img src="../assets/sy_tab_icon_maimai@2x.png" alt />
							<span>出租/卖厂房</span>
						</div>
						<div class="item g_center" @click="changeType('/policy')">
							<img src="../assets/政策.png" alt />
							<span>找政策</span>
						</div>
						<div class="item g_center" @click="changeType('/garden')">
							<img src="../assets/sy_tab_icon_zufang@2x.png" alt />
							<span>找园区</span>
						</div>
					</div>
				</div>
				<div class="inps">
					<div :class="triangletype == 0?'triangle':'triangle1'"></div>
					<div class="search g_center_left">
						<input style="outline: none;" type="text" placeholder="请输入厂房名称" v-model="inputValue"
							placeholder-style="font-size: 14px;font-weight: normal;line-height: 17px;letter-spacing: 0px;color: #C5CBCF;" />
						<div class="buttons_one g_center" @click="go(1,true)">新厂房</div>
						<div class="buttons_tow g_center" @click="go(2,true)">二手厂房</div>
					</div>
					<div class="address g_center_bothSide">
						<div class="address_left g_left_bothSide_v">
							<div class="title">新厂房</div>
							<div class="add_item g_center_left">
								<span v-for="(item,index) in citylist" :key="index">
									<!-- @click="golist(item,'address','new')" -->
									<a
										:href="'/newhouse?city='+item.id+'&id='+item.id+'&name='+item.name+'&type=address'">{{item.name}}</a>
								</span>
							</div>
							<div class="add_item g_center_left">
								<span v-for="(item,index,i) in avrprce" :key="i">
									<!-- @click="golist(index,'area','new')" -->
									<a
										:href="'/newhouse?city='+item+'&item='+item+'&index='+index+'&type=area'">{{item}}</a>
								</span>
							</div>
						</div>
						<div class="partition"></div>
						<div class="address_right g_left_bothSide_v">
							<div class="title">二手厂房</div>
							<div class="add_item g_center_left">
								<span v-for="(item,index) in citylist" :key="index">
									<!-- @click="golist(item,'address','used')" -->
									<a
										:href="'/usedlist?city='+item.id+'&id='+item.id+'&name='+item.name+'&type=address'">{{item.name}}</a>
								</span>
							</div>
							<div class="add_item g_center_left">
								<span v-for="(item,index,i) in secondArea" :key="index">
									<!-- @click="golist(index,'area','used',i)" -->
									<a
										:href="'/usedlist?city='+item+'&item='+item+'&index='+index+'&type=area'">{{item}}</a>
								</span>
							</div>
						</div>
					</div>
					<div class="code">
						<img src="../assets/codes.jpg" alt />
						<div class="code_title g_c_evenly_v">
							<div class="t_top">资深经纪人帮你</div>
							<div class="t_bom">扫码快速出租/售厂房</div>
						</div>
					</div>
				</div>
				<div class="adver g_center_bothSide">
					<advertising />
					<subscribe />
				</div>
				<div class="hourse">
					<div class="big_title g_center_bothSide">
						<div class="t">精选厂房</div>
						<div class="y" @click="go(2)">
							查看更多
							<span class="iconfont icon-xiangyou1"></span>
						</div>
					</div>
					<div class="hourse_item1">
						<list v-for="(item,index) in SecondHourseList" :item="item" :key="index" />
					</div>
				</div>
				<div class="ads" v-if="adsList.length > 0 ">
					<img :src="this.$imgUrl +  adsList[0].imageUrl" alt="">
				</div>
				<div class="hourse">
					<div class="big_title g_center_bothSide fd">
						<div class="g_center_left">
							<div class="fdzy g_center">房东直营 </div>
							<div class="sanjiao">

							</div>
						</div>
						<div class="y">
							<span id="small_title">
								直接与房东联系 零距离-零中介费
							</span>
							<!-- 查看更多 -->
							<!-- <span class="iconfont icon-xiangyou1"></span> -->
						</div>
					</div>
					<div class="hourse_item1">
						<list v-for="(item,index) in SecondHourseList2" :item="item" :key="index" />
					</div>
				</div>
				<div class="hourse">
					<div class="big_title g_center_bothSide">
						<div class="t">热门招商项目</div>
						<div class="y" @click="go(1)">
							查看更多
							<span class="iconfont icon-xiangyou1"></span>
						</div>
					</div>
					<div class="hourse_item g_center_bothSide">
						<project v-for="(item,index) in newhourselist" :item="item" :key="index" />
					</div>
				</div>
				<div class="hourse" v-if="gardenlist.length > 0">
					<div class="big_title g_center_bothSide">
						<div class="t">推荐新入驻园区</div>
						<div class="y" @click="go(3)">
							查看更多
							<span class="iconfont icon-xiangyou1"></span>
						</div>
					</div>
					<div class="hourse_item g_center_bothSide">
						<garden v-for="(item,index) in gardenlist" :item="item" :key="index" />
					</div>
				</div>
				<div class="hourse">
					<div class="big_title g_center_bothSide">
						<div class="t">政策推荐</div>
						<div class="g_center_bothSide">
							<div class="select">
								<span :class="index == tindex?'y':''" v-for="(item,index) in title" :key="index"
									@click="policy(index)">{{item}}</span>
							</div>
							<div class="y" @click="go(4)">
								查看更多
								<span class="iconfont icon-xiangyou1"></span>
							</div>
						</div>
					</div>
					<div class="hourse_item g_center_bothSide">
						<information :datas="policyRecommendationsList" />
					</div>
				</div>
				<footbar />
			</div>

			<!-- <broker /> -->
		</div>
		<el-backtop :right="100" :bottom="100" />
	</div>
</template>
<script>
	// @ is an alias to /src
	import navbar from "@/components/navbars.vue";
	// import broker from '@/components/broker.vue';
	import list from "@/components/list.vue";
	import project from "@/components/project.vue";
	import footbar from "@/components/footbar.vue";
	import garden from "@/components/garden.vue";
	import information from "@/components/information.vue";
	import advertising from "@/components/advertising.vue";
	import subscribe from "@/components/subscribe.vue";
	import {
		getParksList,
		getRegion,
		getAds
	} from "@/api/index";
	import {
		getnewhourselist
	} from "@/api/newhouseapi";
	import {
		getSecondHourseList
	} from "@/api/secondhouseapi";
	import {
		getStore
	} from "@/util/store";
	import {
		getpolicyRecommendations
	} from "@/api/policyapi";
	export default {
		components: {
			navbar,
			// broker,
			list,
			project,
			footbar,
			garden,
			information,
			advertising,
			subscribe
		},
		name: "HomeView",
		metaInfo: {
			title: "厂房出租_厂房出售_二手厂房_产业园区招商-厂牛牛",
			meta: [{
					name: "keywords",
					content: "买厂房，二手厂房,厂房出租,厂房,厂房出售,产业园区，厂房网，仓库，仓库出租，土地招商，土地出售"
				},
				{
					name: "description",
					content: "厂房租售，只上厂牛牛，提供专业的买厂房,二手厂房,厂房出售,厂房出租,产业园招商,厂房网,租厂房,园区招商，提供一站式产业园区招商策划推广服务。"
				}
			]
		},
		data() {
			return {
				inputValue: "",
				title: {},
				tindex: 1,
				gardenForm: {
					current: 1,
					size: 6,
					isRecommend: 1
				},
				newhourseForm: {
					pageNum: 1,
					pageSize: 3,
					isRecommend: 1
				},
				gardenlist: [],
				newhourselist: [],
				SecondHourseList: [],
				SecondHourseList2: [],
				triangletype: 0,
				citylist: [],
				//新房单价
				avrprce: {},
				//二手房面积
				secondArea: null,
				loading: true,
				policyRecommendationsList: [],
				adsList: []
			};
		},
		mounted() {
			this.getDict("parks_industry");
			this.getDict("agent_avatar");
			this.avrprce = this.getDict("new_hourse_avrprce");
			this.secondArea = this.getDict("second_area");
			this.title = this.getDict("policy_categories");
			this.getAdss();
		},
		created() {},
		methods: {
			//政策推荐
			getpolicyRecommendations(key) {
				let data = {
					pageNum: 1, //初始页
					pageSize: 8,
					type: key
				};
				getpolicyRecommendations(data).then(res => {
					if (res.code == 200 && res.success) {
						this.policyRecommendationsList = res.data.data;
						// console.log(this.policyRecommendationsList,"嗯？？？")
					}
				});
			},
			go(e, status) {
				let newUrl = null;
				let lon = getStore({
					name: "location"
				});
				let query = {
					city: lon.dictKey
				};

				if (status) {
					query = {
						inputValue: this.inputValue,
						type: "keyword"
					};
				}
				if (e == 1) {
					newUrl = this.$router.resolve({
						path: `/newhouse`,
						query
					});
				}
				if (e == 2) {
					if (this.triangletype == 1) {
						newUrl = this.$router.resolve({
							path: `/usedlist`,
							query
						});
					} else {
						newUrl = this.$router.resolve({
							path: `/leaselist`,
							query
						});
					}
				}
				if (e == 3) {
					newUrl = this.$router.resolve({
						path: `/gardenlist`
					});
				}
				if (e == 4) {
					newUrl = this.$router.resolve({
						path: `/policylist`
					});
				}
				window.open(newUrl.href, "_blank");
			},
			golist(item, type, houseType, i) {
				if (houseType == "new") {
					let newUrl = null;
					if (type == "area") {
						newUrl = this.$router.resolve({
							path: `/newhouse`,
							query: {
								city: item,
								item: item,
								index: i,
								type: type
							}
						});
						// console.log(item, i)
					} else {
						newUrl = this.$router.resolve({
							path: `/newhouse`,
							query: {
								city: item.id,
								id: item.id,
								name: item.name,
								type: type
							}
						});
					}

					window.open(newUrl.href, "_blank");
				} else {
					let newUrl = null;
					if (type == "area") {
						newUrl = this.$router.resolve({
							path: `/usedlist`,
							query: {
								city: item,
								item: item,
								index: i,
								type: type
							}
						});
						// console.log(item, i)
					} else {
						newUrl = this.$router.resolve({
							path: `/usedlist`,
							query: {
								city: item.id,
								id: item.id,
								name: item.name,
								type: type
							}
						});
					}
					window.open(newUrl.href, "_blank");
				}
			},
			changeType(e) {
				if (e == 0 || e == 1) {
					this.triangletype = e;
				} else {
					let newUrl = this.$router.resolve({
						path: e
					});
					window.open(newUrl.href, "_blank");
				}
			},
			async hanldheadercity(e) {
				this.gardenForm.cityCode = e;
				console.log(this.$route.path);

				getParksList(this.gardenForm).then(res => {
					if (res.code == 200) {
						// console.log(res.data.records)
						this.gardenlist = res.data.records;
					}
				});
				let timer = setTimeout(() => {
					clearTimeout(timer);
					this.avrprce = this.getDict("new_hourse_avrprce");
					this.secondArea = this.getDict("second_area");
					this.title = this.getDict("policy_categories");
					// console.log(this.title,'走到这了')
					if (this.title) {
						this.getpolicyRecommendations(Object.keys(this.title)[0]);
					}
				}, 500);
				this.getnewhouse();
				this.getSecondHourse();
				this.getcity(e);
				this.$router.push({
					path: "/",
					query: {
						city: e
					}
				});
			},
			// 改变政策
			policy(index) {
				// index 是字典的key值
				this.tindex = index;
				this.getpolicyRecommendations(index);
			},
			getnewhouse() {
				this.$forceUpdate();
				getnewhourselist(this.newhourseForm).then(res => {
					if (res.code == 200) {
						this.newhourselist = res.data.list;
					}
				});
			},
			getSecondHourse() {
				let data = {
					pageNum: 1,
					pageSize: 20,
					isSelected: 2
				};
				getSecondHourseList(data).then(res => {
					if (res.code == 200) {
						// console.log(res.data)
						this.SecondHourseList = this.randomList(res.data.data, 4);
						this.directSales();
					}
				});
			},
			directSales() {
				let data = {
					pageNum: 1,
					pageSize: 50,
					isDirectSales: 2
				}
				getSecondHourseList(data).then(res => {
					if (res.code == 200) {
						// console.log(res.data)
						this.SecondHourseList2 = this.randomList(res.data.data, 8);
					}
				});
			},
			randomList(arr, rNum) {
				if (arr.length <= rNum) {
					return arr
				} else {
					let randomArr = [];

					for (let i = 0; i < rNum; i++) {
						let randomIndex = Math.floor(Math.random() * arr.length);
						let randomNum = arr.splice(randomIndex, 1)[0];
						randomArr.push(randomNum);
					}
					return randomArr
				}

			},
			getcity(e) {
				let data = {
					parentId: e,
					level: 3
				};
				getRegion(data).then(res => {
					if (res.code == 200) {
						this.citylist = res.data;
					}
				});
			},
			getAdss() {
				getAds(35).then(res => {
					console.log(res.data)
					// this.total = res.data.length;
					this.adsList = res.data;
				})
			}
		}
	};
</script>
<style lang="scss" scoped>
	a {
		text-decoration: none;
		color: #333333;

		&:hover {
			color: #2a46f7;
			text-decoration: underline;
		}
	}

	.home {
		// position: fixed;
		// top: 0;
	}

	#bg {
		background-image: url(../assets/bg.png);
		background-size: cover;
		// background-attachment: fixed;
		background-position: center;
		width: 100%;
		height: 420px;
		margin: 0 auto;
		// margin-bottom: 180px;
	}

	#site {
		width: 1200px;
		color: #ffffff;
		position: relative;
		top: 0;
		z-index: 9;
		/* border:1px solid red; */
		margin: 0 auto;
		margin-top: 85px;
	}

	.site_title {
		width: 100%;
		font-family: Alibaba PuHuiTi 2;
		font-size: 40px;
		font-weight: bold;
		line-height: 48px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align: left;
	}

	.navlist {
		width: 100%;

		font-size: 18px;
		font-weight: normal;
		line-height: 22px;
		letter-spacing: 0px;
		color: #ffffff;
		height: 52px;
		margin-top: 46px;

		.item {
			margin-right: 30px;
		}

		.item>img {
			margin-right: 2px;
		}
	}

	.inps {
		width: 1140px;
		padding: 30px;
		height: 200px;
		border-radius: 4px;
		opacity: 1;
		background: #ffffff;
		box-shadow: 0px 2px 10px 0px rgba(71, 75, 78, 0.16);
		margin: 0 auto;
		position: relative;
	}

	.triangle {
		position: absolute;
		top: -20px;
		left: 35px;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid transparent;
		border-bottom: 10px solid #ffffff;
		// margin-left: 30px;
	}

	.triangle1 {
		@extend .triangle;
		left: 135px;
	}

	.search>input {
		width: 650px;
		height: 48px;
		border-radius: 1px;
		opacity: 1;
		box-sizing: border-box;
		border: 1px solid #cbd0d4;
		padding-left: 13px;
		font-size: 14px;
		font-weight: normal;
		line-height: 17px;
		letter-spacing: 0px;
		// color: #C5CBCF;
	}

	.buttons_one {
		width: 108px;
		height: 48px;
		border-radius: 2px;
		opacity: 1;
		background: #2a46f7;
		font-size: 18px;
		color: #ffffff;
		margin-left: 10px;

		&:hover {
			opacity: 0.9;
			cursor: pointer;
		}
	}

	.buttons_tow {
		width: 108px;
		height: 48px;
		border-radius: 2px;
		opacity: 1;
		background: #fd4e54;
		font-size: 18px;
		color: #ffffff;
		margin-left: 10px;

		&:hover {
			opacity: 0.9;
			cursor: pointer;
		}
	}

	.code {
		width: 170px;
		/* height: 180px; */
		border-radius: 4px;
		opacity: 1;
		border: 1px solid #2a46f7;
		position: absolute;
		top: 30px;
		right: 30px;

		img {
			width: 118px;
			height: 118px;
		}
	}

	.code_title {
		background: #2a46f7;
		color: #ffffff;
		padding: 15px 0;
	}

	.t_top {
		font-size: 16px;
	}

	.t_bom {
		font-size: 14px;
		margin-top: 2px;
	}

	.item:hover {
		/* color: #2a46f7; */
		cursor: pointer;
		user-select: none;
	}

	.address {
		width: 940px;
		height: 100px;
		/* border: 1px solid #333333; */
		margin-top: 25px;
	}

	.address .title {
		font-family: Alibaba PuHuiTi 2;
		font-size: 14px;
		font-weight: 600;
		line-height: 17px;
		letter-spacing: 0px;
		color: #0b0f12;
	}

	.add_item {
		width: 100%;

		font-size: 14px;
		font-weight: normal;
		line-height: 17px;
		letter-spacing: 0px;
		color: #333333;
		flex-wrap: wrap;
	}

	.add_item span {
		display: block;
		margin-right: 17px;
		margin-top: 10px;

		&:hover {
			// text-decoration: underline;
			color: #2a46f7;
			cursor: pointer;
		}
	}

	.address_left {
		width: 443px;
		height: 100%;
		/* border: 1px solid red; */
	}

	.address_right {
		width: 443px;
		height: 100%;
		/* border: 1px solid red; */
	}

	.partition {
		width: 0;
		height: 100px;
		border: 1px solid #f0f4f7;
	}

	.adver {
		width: 1200px;
		margin: 0 auto;
		margin-top: 40px;
	}

	.hourse {
		width: 1200px;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 20px;

		.big_title {
			width: 100%;

			#small_title {
				font-size: 15px;
				// color: #ff5e06;
				color: #a4030c;
				font-weight: 600;
				font-style: italic;
				letter-spacing: 2px;
			}

			font-size: 24px;
			font-weight: 600;
			letter-spacing: 0px;
			color: #333333;

			.y {
				font-size: 15px;
				line-height: 18px;
				color: #ff5e06;
				cursor: pointer;

				&:hover {
					// text-decoration: underline;
				}
			}

			.fdzy {
				width: 160px;
				height: 40px;
				background: linear-gradient(to right bottom,rgba(164, 5, 13,0.95),rgba(164, 5, 13,0.5),rgba(164, 5, 13,0.8),rgba(164, 5, 13,0.95),rgba(164, 5, 13,1),rgba(164, 5, 13,1)) ;
				color: #ffffff;
				font-weight: 600;
				font-size: 24px;
			}

			.sanjiao {
				width: 5px;
				height: 0;
				border: 20px solid rgba(164, 5, 13,0.95);
				border-right-color: transparent;
				border-top-color: transparent;
			}

			.select {
				font-size: 15px;
				margin-right: 20px;

				span {
					display: inline-block;
					padding: 0 10px;
					cursor: pointer;

					&:hover {
						// text-decoration: underline;
						color: #ff5e06;
					}
				}
			}
		}
		.fd{
			border-bottom: 2px solid #a4030c;
		}

		.hourse_item {
			width: 100%;
			// flex-wrap: wrap;
			display: grid;
			justify-content: space-between;
			grid-template-columns: auto auto auto;
		}

		.hourse_item1 {
			width: 100%;
			// flex-wrap: wrap;
			display: grid;
			justify-content: space-between;
			grid-template-columns: auto auto auto auto;
		}
	}

	.ads {
		width: 1200px;
		height: auto;
		margin: 10px auto;

		img {
			width: 100%;
			height: 100%;
		}
	}
</style>