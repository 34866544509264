<template>
	<div>
		<div class="item1 g_center_bothSide" v-if="type">
			<div class="img1">
				<img class="img_i" src="../assets/userimg.png" alt="">
			</div>
			<div class="item_r1 g_center_bothSide">
				<div class="item_r_l g_left_evenly_v">
					<div class="name">经纪人A</div>
					<div class="identification g_center_left">
						<img src="../assets/v.png" alt="">
						<div>"我有 7 年从业经验，长期服务 溧水 区域，服务客户 3705 次，欢迎咨询"</div>
					</div>
				</div>
				<div class="item_right g_center_bothSide">
					<div class="item_btn1 g_center">
						<span class="iconfont icon-dianhua"></span>
						<!-- <img src="../assets/phone.png" alt=""> -->
						<div>电话咨询</div>
					</div>
					<a
						href="https://p.qiao.baidu.com/cps/chat?siteId=17687340&amp;userId=38660150&amp;siteToken=af31b431ab708447adef989371b4fcc0&amp;cp=cnn168.com%2F&amp;cr=cnn168.com%2F&amp;cw=">
						<div class="item_btn2 g_center">
							<div>立即咨询</div>
						</div>
					</a>

				</div>
			</div>
		</div>
		<div class="item g_end_bothSide" v-else>
			<div class="img">
				<img class="img_i" :src="item.avatar?domain.img +item.avatar:getDict('agent_avatar')['agent_man']" alt="">
			</div>
			<div class="item_r g_center_bothSide">
				<div class="item_r_l g_left_bothSide_v">
					<div class="name">{{item.name}}</div>
					<div class="identification g_center_left">
						<img src="../assets/v.png" alt="">
						<div>选址顾问</div>
					</div>
				</div>
				<div class="item_btn g_center" @click.stop="dialogTableVisible = true">
					<!-- <img src="../assets/phone.png" alt=""> -->
					<span class="iconfont icon-dianhua"></span>
					<div>立即咨询</div>
				</div>
			</div>
		</div>

	</div>
	<el-dialog v-model="dialogTableVisible" title=" " width="370" append-to-body>
		<div class="tem g_c_evenly_v">
			<img :src="item.avatar?domain.img + item.avatar:getDict('agent_avatar')['agent_man']" alt="">
			<div class="name">
				{{item.name}}
			</div>
			<div class="experience">
				{{item.year}} 年经验 | 服务客户 728 次
			</div>
			<div class="phone">
				<span class="iconfont icon-dianhua"></span>{{item.phone}}
			</div>
			<!-- <div class="times">
				· 此号码 282 秒内拨打有效，超时请刷新
			</div> -->
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props: {
			type: String,
			item: Object
		},
		data() {
			return {
				dialogTableVisible: false,
				domain: {
					img: "http://img.myzc88.com",
					ossimg: "http://myzcoss.oss-cn-hangzhou.aliyuncs.com/",
				},
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tem {
		width: 100%;

		img {
			width: 100px;
			height: 100px;
			border-radius: 50%;
		}

		.name {
			font-size: 24px;
			font-weight: 900;
			text-align: center;
			letter-spacing: 0em;
			color: #3D3D3D;
			padding-top: 8px;
		}

		.experience {
			padding-top: 15px;
			font-size: 18px;
			text-align: center;
			color: #666666;
		}

		.phone {
			font-size: 32px;
			color: #FF5E06;
			margin-top: 44px;

			span {
				font-size: 32px;
			}
		}

		.times {
			margin-top: 17px;
			font-family: Source Han Sans CN;
			font-size: 16px;
			font-weight: normal;
			text-align: center;
			letter-spacing: 0em;
			color: #8F98A3;
		}
	}

	.item {
		margin-top: 9px;
		width: 100%;

		.img {
			width: 46px;
			height: 46px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 10px;

			.img_i {
				width: 46px;
				height: 46px;
			}
		}

		.img1 {
			@extend .img;
			width: 60px;
			height: 60px;
			background: #2A46F7;

			.img_i {
				width: 60px;
				height: 60px;
			}
		}

		.item_r {
			flex: 1;
			height: 40px;


			.item_r_l {
				flex: 1;
				height: 40px;

				.name {
					font-size: 16px;
				}

				.identification {
					font-size: 12px;
					font-weight: normal;
					line-height: 14px;
					letter-spacing: 0px;
					color: #8F98A3;
					text-shadow: 0px 2px 10px 0px rgba(71, 75, 78, 0.16);

					img {
						margin-left: -2px;
						margin-right: 2px;
					}
				}
			}

			.item_btn {
				width: 100px;
				height: 36px;
				border-radius: 2px;
				opacity: 1;
				box-sizing: border-box;
				border: 1px solid #2A46F7;
				box-shadow: 0px 2px 10px 0px rgba(71, 75, 78, 0.16);
				font-family: HarmonyOS Sans;
				font-size: 12px;
				font-weight: 250;
				line-height: 14px;
				letter-spacing: 0px;

				color: #2A46F7;

				text-shadow: 0px 2px 10px 0px rgba(71, 75, 78, 0.16);

				div {
					margin-left: 8px;
				}
			}

			.item_btn:hover {
				// transform: scale(1.05);
				background: #e6f4ff;
				// color: #ffffff;
				cursor: pointer;
			}

			.item_btn1 {
				@extend .item_btn;
				background: #e9ecfe;
			}

			.item_btn2 {
				@extend .item_btn;
				background: #2a46f7;
				color: #ffffff;
				margin-left: 10px;
			}
		}

		.item_r1 {
			@extend .item_r;
			height: 60px;
			margin-left: 10px;

			.item_r_l {
				height: 60px;
			}
		}
	}

	.item1 {
		@extend .item;
		background: #ffffff;
		margin-top: 0;
	}
</style>