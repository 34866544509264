import request from "@/common/request";  //导入封装请求的js文件

//获取字典集合
export function diclist(params) {
  return request({
    url: "/api/blade-system/open/dict/dictionary",  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

export function products(params) {
  return request({
    url: "/api/v1/products",  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}
//获取周边
export function getplace(params) {
  return request({
    url: "https://restapi.amap.com/v3/place/around",  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}

 
 //登录注册
export function login(data) {
  return request({
    url: `/api/user-website/userwebsite/login`,
    method: "post",
    data,
    // post请求接口后面拼接参数
    // params: {
    //   currentOpenId: 'currentOpenId',
    //   currentCompanyOpenId: 'currentCompanyOpenId'
    // }
  });
}

//获取手机验证码

export function captcha(params) {
  return request({
    url: `/api/user-website/userwebsite/sms/captcha`,  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}



 //刷新令牌
export function refreshToken(data) {
  return request({
    url: `/api/user-website/userwebsite/refreshToken`,
    method: "post",
    data
  });
}

// 获取用户信息

export function getUserInfo(params) {
  return request({
    url: `/api/user-website/userwebsite/getUserDetail`,  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

// 获取园区

export function getAds(type) {
	let data = {
		adsType:13,
		pushSource:type //新园区
	};
  return request({
    url: `/api/open/ads/list`,  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params:data  //接口参数
  });
}
//获取字典
export function getDictionary(params) {
  return request({
    url: `/api/blade-system/open/dict/dictionary`,  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}
//获取字典多个code
export function getDictionaryArray(params) {
  return request({
    url: `/api/blade-system/open/dict/dictionaryArray`,  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}


//委托

export function postEntrust(data) {
  return request({
    url: `/api/open/delegateDemand/submit`,
    method: "post",
    data
  });
}



//获取委托总数
export function getNumber(params) {
  return request({
    url: `/api/open/delegateDemand/getNumber`,  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}

//园区

export function getParksList(params) {
  return request({
    url: `/api/open/parks/list`,  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'multipart/form-data' }, //给接口添加请求头
    params  //接口参数
  });
}
//省市区地址
//parentId 父级
//level 层级：省level=1，市level=2，区level=3，街道level=4
export function getRegion(params) {
  return request({
    url: "/api/open/area/getRegion",  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//隐私政策，协议
export function GetConfigcContent(params) {
  return request({
    url: "/api/user-website/userwebsite/getUserAgreement",  //接口路径
    method: "get",  //接口方法
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

export function adClickLogs(data) {
  return request({
    url: `/api/open/adClickLogs/save`,
    method: "post",
    data
  });
}
