<template>
	<div>
		<el-dialog v-model="dialogTableVisible" title=" " width="450" :append-to-body="true" :close-on-click-modal="false"
			:destroy-on-close="true" @closed="closeLogin" :z-index="99999999999999999">
			<div class="tem g_center_bothSide_v">
				<div class="tem_top g_c_evenly_v">
					<div class="g_center">
						<img src="../assets/logosmall.png" alt="">登录厂牛牛
					</div>
					<div class="tem_top_bom">
						未注册手机号将自动创建账号
					</div>
				</div>
				<el-form ref="ruleFormRef" :rules="rules" :model="form">
					<div class="inp">
						<el-form-item prop="phone">
							<el-input v-model="form.phone" :input-style="style" placeholder="请输入手机号码"
								onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="11" />
						</el-form-item>
					</div>
					<div class="inp g_start_bothSide">
						<el-form-item prop="code">
							<el-input v-model="form.code" :input-style="style1" placeholder="请输入手机验证码"
								onkeyup="value=value.replace(/[^\d]/g,'')" />
						</el-form-item>
						<div class="verify g_center" @click="getCode">
							{{timers==60?'获取验证码':`${timers}秒后可重发`}}
						</div>
					</div>
				</el-form>
				<div class="g_center" :class="btnstatus?'login1':'login'" @click="btnstatus?submit():''">
					登录
				</div>
				<div class="prompted g_center">
					登录即代表<a @click="goAboutView(11)">《用户服务协议》</a>和<a @click="goAboutView(12)">《隐私政策》</a>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		captcha,
		login,
		getDictionary
	} from '@/api/index.js'
	import {
		getStore,
		setStore
	} from '@/util/store'
	export default {
		data() {
			return {
				dialogTableVisible: true,
				form: {
					phone: '',
					code: ''
				},
				rules: {
					phone: [{
							required: true,
							message: '请填写手机号码',
							trigger: 'blur'
						},
						{
							pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
							message: '请输入正确的手机号码或者座机号'
						}
					],
				},
				timers: 60,
				style: {
					height: '43px'
				},
				style1: {
					height: '43px',
					width: '220px'
				},
			}
		},
		computed: {
			btnstatus: function() {
				if (this.form.phone != '' && this.form.phone != undefined && this.form.code != '' && this.form.code !=
					undefined) {
					return true;
				} else {
					return false;
				}
		
			}
		},
		methods: {
			//协议
			goAboutView(e) {
				let newUrl = this.$router.resolve({
					path: "/AboutView",
					query: {
						type: e
					}
				});
				window.open(newUrl.href, "_blank");
			},
			//关闭登录
			closeLogin() {
				
				this.$emit('change','')
				console.log("关闭")
			},
			submit() {
				this.rules = {
					phone: [{
						required: true,
						message: '请填写手机号码',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请填写验证码',
						trigger: 'blur'
					}]
				}
				this.$refs.ruleFormRef.validate((valid, fields) => {
					if (valid) {
						console.log('submit!')
						let that = this
						this.$store.dispatch("LoginByPhone", this.form).then((r) => {
							if (r == 'error') {
								that.timers = 0;
								this.rules = {
									phone: [{
										required: true,
										message: '请填写手机号码',
										trigger: 'blur'
									}]
								}
							} else {
								that.$store.dispatch("GetUserInfo").then(res => {
									that.dialogTableVisible = false;
									that.$emit('change','')
									that.userInfo = res
								});
							}
						});
						// login(this.form).then(res =>{
						// 	console.log(res)
						// 	if(res.code == 200){
						// 		this.$store.state.token = res.data.accessToken;

						// 	}
						// })
					} else {
						console.log('error submit!', fields)
					}
				})

			},
			getCode() {
				if (this.timers == 60) {
					this.$refs.ruleFormRef.validate((valid, fields) => {
						if (valid) {
							// console.log('submit!')
							captcha(this.form).then(res => {
								// console.log(res, "验证码")
								if (res.code == 200) {
									this.form.codeKey = res.data.codeKey
								}
								this.jishi();
							})
			
						} else {
							console.log('error submit!', fields)
						}
					})
				} else {
			
				}
			
			},
			
			jishi() {
				let timer = setInterval(() => {
					if (this.timers == 0) {
						clearInterval(timer);
						this.timers = 60;
						return
					}
					this.timers--;
				}, 1000)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.tem {
		width: 100%;
		min-height: 316px;
		margin-top: -40px;

		.tem_top {
			img {
				width: 23px;
				height: 24px;
				margin-right: 5px;
			}

			font-size: 18px;
			font-weight: 900;
			line-height: 34px;
			letter-spacing: 0px;
			color: #3D3D3D;

			.tem_top_bom {
				font-size: 14px;
				font-weight: normal;
				line-height: 34px;
				letter-spacing: 0px;
				color: #979797;
			}
		}

		.inp {
			width: 370px;
			height: 43px;
			margin-top: 30px;
		}

		.login {
			width: 370px;
			height: 43px;
			border-radius: 2px;
			opacity: 1;
			background: #FFFFFF;
			border: 1px solid #2A46F7;
			font-size: 16px;
			font-weight: bold;
			color: #2A46F7;
			margin-top: 22px;
			cursor: pointer;
		}

		.login1 {
			@extend .login;
			background: #2A46F7;
			color: #FFFFFF;
		}

		.prompted {
			width: 100%;
			margin-top: 22px;
			font-size: 13px;
			font-weight: bold;
			line-height: 34px;
			letter-spacing: 0px;
			text-decoration: underline;
			color: #979797;

			a {
				color: #979797;

				&:hover {
					color: #2A46F7;
					cursor: pointer;
				}
			}
		}

		.verify {
			width: 95px;
			height: 43px;
			border-radius: 2px;
			opacity: 1;
			background: #2A46F7;
			color: #ffffff;
			font-size: 14px;
			// margin-left: 20px;
			padding: 0 10px;
			cursor: pointer;
			user-select: none;

			&:hover {
				opacity: 0.95;
			}
		}
	}
</style>