<template>
	<div class="all">
		<div class="title g_center_bothSide">
			<div class="title_left">
				预约看厂
			</div>
			<div class="title_right">
				<el-pagination small :page-size="2" @current-change="currentChange" :pager-count="2"
					layout="prev, pager, next" :total="total" />
			</div>
		</div>
		<div class="tow g_center_left">
			<div class="g_center">
				<img src="@/assets/dui.png" alt="">
				<span>随时看房</span>
			</div>
			<div class="g_center">
				<img src="@/assets/dui.png" alt="">
				<span>专属顾问接待</span>
			</div>
			<div class="g_center">
				<img src="@/assets/dui.png" alt="">
				<span>预约有优惠</span>
			</div>
		</div>
		<div class="sub_card">
			<div class="sub_card_item g_left_bothSide_v" v-for="(item,index) in list[page]" :key="index">
				<div class="address g_left_bothSide_v">
					<span>{{item.titleOne}}</span>
					<span>{{item.titleTwo}}</span>
				</div>
				<div class="recommend g_left_bothSide_v">
					<span>推荐理由：{{item.titleThree}}</span>
					<span>优惠详情：咨询平台</span>
				</div>
				<div class="bom g_center_bothSide">
					<div><span style="color: #2a46f7;">{{item.numberOfClicks}}</span>人已报名</div>
					<!-- <el-popover placement="bottom" title="联系平台" :width="200" content="平台电话:400-660-1258"
						trigger="click" @show="clickPoint(item.id)">
						<template #reference>
							<div class="bom_btn g_center" @click="dialogTableVisible = true">
								预约看厂
							</div>
						</template>
					</el-popover> -->
					<a
						href="https://p.qiao.baidu.com/cps/chat?siteId=17687340&amp;userId=38660150&amp;siteToken=af31b431ab708447adef989371b4fcc0&amp;cp=cnn168.com%2F&amp;cr=cnn168.com%2F&amp;cw=" target="_blank">
						<div class="bom_btn g_center">
							预约看厂
						</div>
					</a>

				</div>
			</div>
		</div>
		<borkerdialog v-if="dialogTableVisible" @change="this.dialogTableVisible = false" :type="1" />
	</div>

</template>
<script>
	import {
		getAds,
		adClickLogs
	} from '@/api/index';
	import borkerdialog from '@/components/borkerdialog.vue';
	export default {
		components: {
			borkerdialog
		},
		data() {
			return {
				list: [],
				total: 0,
				page: 0,
				dialogTableVisible: false
			}
		},
		methods: {
			getList() {
				getAds(32).then(res => {
					// console.log(res.data)
					this.total = res.data.length;
					this.list = this.group(res.data, 2);
				})
			},
			group(array, subGroupLength) {
				let index = 0;
				let newArray = [];
				while (index < array.length) {
					newArray.push(array.slice(index, index += subGroupLength));
				}
				return newArray;
			},
			currentChange(e) {
				// console.log(e)
				this.page = e - 1;
			},
			clickPoint(e) {

				adClickLogs({
					adId: e
				}).then(res => {
					// console.log(res);
				})
			}
		},
		created() {
			this.getList();
		}
	}
</script>

<style lang="scss" scoped>
	.all {
		width: 300px;
		height: 430px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		// border: 1px solid red;
		a {
			text-decoration: none;
		}

		.title {
			width: 100%;

			letter-spacing: 0px;
			font-weight: 600;
			color: #333333;

			.title_left {
				font-size: 24px;
				line-height: 29px;
			}

			.title_right {
				font-size: 12px;
				line-height: 14px;

				span {
					cursor: pointer;
					display: inline-block;
					padding: 0 6px;

					&:hover {
						color: #000;
					}
				}
			}

			margin-bottom: 23px;
		}

		.tow {
			font-size: 14px;
			font-weight: normal;
			line-height: 17px;
			letter-spacing: 0px;
			color: #333333;

			div {
				margin-right: 12px;
			}

			img {
				width: 12px;
				height: 12px;
				margin-right: 3px;
			}
		}

		.sub_card {
			width: 100%;
			// margin-top: 30px;

			.sub_card_item {
				margin-top: 27px;

				&:last-of-type {
					border-bottom: none;
				}

				border-bottom: 1px dashed #ebedf0;
				padding-bottom: 20px;
				// height: 160px;

				.address {

					font-size: 14px;
					font-weight: normal;
					line-height: 22px;
					letter-spacing: 0px;
					color: #333333;

				}

				.recommend {

					font-size: 12px;
					font-weight: normal;
					line-height: 22px;
					letter-spacing: 0px;
					color: #666666;
					margin-top: 8px;
					text-align: left;
				}

				.bom {
					width: 100%;
					margin-top: 18px;
					cursor: pointer;

					.bom_btn {
						width: 78px;
						height: 32px;
						border-radius: 1px;
						opacity: 1;
						background: #2A46F7;
						font-size: 12px;
						font-weight: normal;
						line-height: 14px;
						text-align: center;
						letter-spacing: 0px;
						color: #FFFFFF;
						opacity: 0.9;

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
</style>