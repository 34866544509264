<template>
	<div :class="index?'headers':''">
		<div class="g_center_bothSide" :class="index?'all1':'all'">
			<div class="left g_center_left">
				<img style="cursor: pointer;" v-if="index" src="../assets/logo1.png" alt="" @click="go(0)">
				<img v-else src="../assets/logo.png" alt="">
				<el-popover v-if="showLon" :width="400" placement="bottom-start" v-model:visible="citysVisible">
					<template #reference>
						<div class="btn g_center_evenly">
							<span class="iconfont icon-weizhi"></span>
							{{citydatas?citydatas.city:'定位中'}}
							<span class="iconfont icon-xiala_mianxing"></span>
						</div>
					</template>
					<template #default>
						<div class="citys">
							<div class="fire g_center_bothSide">
								<span class="iconfont icon-huo"></span>
								<span>热门城市</span>
							</div>
							<div class="citys_content">
								<span v-for="(item,index) in cityList" :key="index"
									@click="citychoose(item)">{{item.dictValue}}</span>
							</div>
						</div>
					</template>
				</el-popover>
				<div class="" style="width: 120px;">

				</div>

				<div class="navlist g_center_bothSide" :style="showLon?'':'margin-left: 108px;'">
					<a href="/newhouse" target="_blank" >
						<div class="item" :class="index == 1?'item1':''">新房</div>
					</a>
					<a href="/usedlist" target="_blank">
						<div class="item" :class="index == 2?'item1':''">二手房</div>
					</a>
					<a href="/leaselist" target="_blank">
						<div class="item" :class="index == 3?'item1':''">二手租赁</div>
					</a>
					<a href="/garden" target="_blank">
						<div class="item" :class="index == 4?'item1':''">云上园区</div><!-- @click="go(4)" -->
					</a>
					<a href="/policy" target="_blank">
						<div class="item" :class="index == 5?'item1':''">政策通 </div><!-- @click="go(5)" -->
					</a>
					<a href="/entrust" target="_blank">
						<div class="item" :class="index == 6?'item1':''">在线委托</div>
					</a>
				</div>
			</div>
			<div class="right g_center_bothSide">
				<div class="login item" v-if="!userInfo" @click="dialogTableVisible = true">注册/登录</div>
				<div class="login item" v-else @click="go(7)">{{userInfo.name}}</div>
				<!-- <el-popover placement="bottom" title="联系平台" :width="200" content="平台电话:400-660-1258" trigger="hover">
					<template #reference>
						<div class="cooperation g_center" @click="dialogTableVisibleY = true">园区合作</div>
					</template>
				</el-popover> -->
				<a href="https://p.qiao.baidu.com/cps/chat?siteId=17687340&amp;userId=38660150&amp;siteToken=af31b431ab708447adef989371b4fcc0&amp;cp=cnn168.com%2F&amp;cr=cnn168.com%2F&amp;cw="
					target="_blank">
					<div class="cooperation g_center">园区合作</div>
				</a>

			</div>
		</div>
		<borkerdialog v-if="dialogTableVisibleY" @change="this.dialogTableVisibleY = false" :type="1" />
		<logins v-if="dialogTableVisible" @change="userInfoChange" />
	</div>
</template>
<style>
	body {
		padding: 0;
		margin: 0;
	}
</style>
<style lang="scss" scoped>
	@import url("../../common/css/style.css");

	a {
		text-decoration: none;
		color: #FFFFFF;

		&::after {
			background: #FFFFFF;
		}
	}

	.citys {
		width: 340px;
		min-height: 50px;
		// border: 1px solid red;
		padding: 20px;

		.fire {
			width: 105px;

			span {
				display: block;
				font-family: Alibaba PuHuiTi 2.0;
				font-size: 20px;
				font-weight: bold;
				line-height: 24px;
				letter-spacing: 0px;
				color: red;

				// margin-right: 3px;
				&:last-of-type {
					color: #0B0F12;
				}
			}
		}

		.citys_content {
			span {
				cursor: pointer;
				display: inline-block;
				font-family: Alibaba PuHuiTi 2.0;
				font-size: 18px;
				font-weight: bold;
				line-height: 24px;
				letter-spacing: 0px;
				color: #0B0F12;
				margin: 30px 20px 0 0;

				// &:hover {
				// 	text-decoration: underline;
				// 	color: #2A46F7;
				// }
			}
		}
	}

	.el-dialog {
		position: absolute;
		z-index: 8;
	}

	.headers {
		// width: 100%;
		background: #ffffff;
		border: 1px solid #f2f2f2;
	}

	.all {
		width: 1200px;
		margin: 0 auto;
		/* background: #333333; */
		height: 80px;
		color: #ffffff;

		font-size: 16px;
		font-weight: normal;
		line-height: 19px;
		letter-spacing: 0px;
		position: relative;
		z-index: 8;

		.navlist {
			width: 478px;
			// margin-left: 108px;

			.item1 {
				color: #2a46f7;
			}
		}

		// .item:hover {
		// 	// color: #2a46f7;
		// 	cursor: pointer;
		// 	user-select: none;
		// 	text-decoration: underline;
		// }

		.btn {
			min-width: 86px;
			height: 26px;
			border-radius: 15px;
			opacity: 1;
			margin-left: 16px;
			border: 1px solid #FFFFFF;
			cursor: pointer;
			padding: 2px 5px;
		}

		.right {
			width: 260px;

			.login {
				width: 160px;
				font-size: 16px;
				cursor: pointer;
			}
		}

		.cooperation {
			width: 98px;
			height: 35px;
			border-radius: 2px;
			opacity: 1;
			border: 1px solid #FFFFFF;
			cursor: pointer;

			&:hover {
				transform: scale(1.05, 1.05);
				background-color: #ffffff;
				color: #2a46f7;
			}
		}


	}

	.all1 {
		@extend .all;
		color: #333333;

		a {
			color: #333333;
			&::after{
				background: #2A46F7;
			}
			
		}

		.cooperation {
			background: #2a46f7;
			color: #ffffff;

			&:hover {
				transform: scale(1.05, 1.05);
				background: #2a46f7;
				color: #ffffff;
			}
		}

		.btn {
			border: 1px solid #2a46f7;
			color: #2a46f7;
		}

		.item:hover {
			color: #2a46f7;
			cursor: pointer;
			user-select: none;
			// text-decoration: underline;
		}
	}
</style>
<script>
	// document.querySelectorAll('a').forEach(elem => {

	// 	elem.onmouseenter =
	// 		elem.onmouseleave = e => {

	// 			const tolerance = 5;

	// 			const left = 0;
	// 			const right = elem.clientWidth;

	// 			let x = e.pageX - elem.offsetLeft;

	// 			if (x - tolerance < left) x = left;
	// 			if (x + tolerance > right) x = right;

	// 			elem.style.setProperty('--x', `${x}px`);

	// 		};
	// });
	window._AMapSecurityConfig = {
		securityJsCode: "c2edb8137b4a1ae84e064cb9cf03fa97"
	}
	import {
		getDictionary
	} from '@/api/index.js'
	import {
		getStore,
		setStore
	} from '@/util/store'
	//引入高德地图
	import AMapLoader from '@amap/amap-jsapi-loader';
	import borkerdialog from '@/components/borkerdialog.vue';
	import logins from '@/components/login.vue';
	export default {
		props: {
			index: Number,
			logins: {
				type: Boolean,
				default: () => {
					return false
				}
			}
		},
		components: {
			borkerdialog,
			logins
		},
		created() {

			let userInfo = getStore({
				name: 'userInfo'
			})
			console.log(this.getQueryString("city"))
			if (this.$route.name == 'garden' || this.$route.name == 'gardenlist' || this.$route.name == 'gardendetails' ||
				this.$route.name == 'policy' || this.$route.name == 'policylist' || this.$route.name == 'policydetails' ||
				this.$route.name == 'entrust' || this.$route.name == 'useddetails' || this.$route.name == 'leasedetails' ||
				this.$route.name == 'newhousedetails') {
				this.showLon = false
			} else {
				let c = this.getQueryString("city")
				if(this.getQueryString("cityname")){
					this.citydatas = {};
					this.citydatas.city = this.getQueryString("cityname");
					setStore({
						name: 'location',
						content: {
							city: this.citydatas.city,
							dictKey: c
						}
					})
					this.$emit('citychange', c);
				}else{
					let lon = getStore({
						name: 'location'
					})
					console.log(lon);
					if (lon) {
						// console.log('服了', this.$store.state.location);
						this.$emit('citychange', lon.dictKey);
						this.citydatas = {};
						this.citydatas.city = lon.city;
					} else {
						this.citydatas = {};
						this.citydatas.city = '嘉兴市';
						setStore({
							name: 'location',
							content: {
								city: '嘉兴市',
								dictKey: 330400
							}
						})
						this.$emit('citychange', 330400);
					}
				}
				
				this.lon = getStore({
					name: 'location'
				})

				getDictionary({
					code: 'pc_home_heart_city'
				}).then(res => {
					if (res.code == 200) {
						this.cityList = res.data
						// console.log(res.data)
					}

				})
			}

			if (userInfo) {
				this.userInfo = userInfo
			}
		},
		data() {
			return {
				dialogTableVisibleY: false,
				showLon: true,
				citysVisible: false,
				visible: false,
				cityList: [],
				dialogTableVisible: false,
				userInfo: null,
				citydatas: null
			}
		},
		methods: {
			getQueryString(name) {
				var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
				var r = window.location.search.substr(1).match(reg);
				if (r != null) return decodeURI(r[2]);
				return null;
			},
			userInfoChange() {
				this.dialogTableVisible = false;
				let userInfo = getStore({
					name: 'userInfo'
				})
				this.userInfo = userInfo
			},
			citychoose(e) {
				this.citysVisible = false;
				// this.$emit('citychange', e);
				setStore({
					name: 'location',
					content: {
						city: e.dictValue,
						dictKey: e.dictKey
					}
				});
				this.$emit('citychange', e.dictKey);
				this.citydatas.city = e.dictValue;

			},
			initMap() {
				let that = this;
				AMapLoader.load({
					// key:'51fad2ee0b6d6854aeecdb78298c2e5c',
					key: "81c71d47354c0d627b4bfcf99242e871",
					version: "2.0",
					resizeEnable: true,
					plugins: ["AMap.CitySearch"],
				}).then((AMap) => {
					console.log(AMap);
					AMap.plugin('AMap.CitySearch', function() {
						var citySearch = new AMap.CitySearch()
						citySearch.getLocalCity(function(status, result) {
							if (status === 'complete' && result.info === 'OK') {
								// 查询成功，result即为当前所在城市信息
								console.log(result)
								that.citydatas = result;
								//逆向地理编码
								AMap.plugin('AMap.Geocoder', function() {
									var geocoder = new AMap.Geocoder({
										// city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
										city: result.city
									})
									//逆向获取城市信息
									geocoder.getLocation(result.city,
										function(status, data) {
											if (status ===
												'complete' && data
												.info === 'OK') {
												that.$emit(
													'citychange',
													data.geocodes[
														0].adcode);
												setStore({
													name: 'location',
													content: {
														city: that
															.citydatas
															.city,
														dictKey: data
															.geocodes[
																0
															]
															.adcode
													}
												});

											}
										})
								})
							}
						})

					})
				}).catch(e => {
					console.log(e);
				})
			},

			go(e) {

				let newUrl = null;
				let lon = getStore({
					name: 'location'
				})
				if (e == 0) {
					newUrl = this.$router.resolve({
						path: "/"
					});
				}
				if (e == 1) {
					newUrl = this.$router.resolve({
						path: "/newhouse?city=" + lon.dictKey
					});
				}

				if (e == 2) {
					newUrl = this.$router.resolve({
						path: "/usedlist?city=" + lon.dictKey
					});
				}

				if (e == 3) {
					newUrl = this.$router.resolve({
						path: "/leaselist?city=" + lon.dictKey
					});
				}
				if (e == 4) {
					newUrl = this.$router.resolve({
						name: "garden"
					});
				}

				if (e == 5) {
					newUrl = this.$router.resolve({
						path: "/policy"
					});
				}

				if (e == 6) {
					newUrl = this.$router.resolve({
						name: "entrust"
					});
				}

				if (e == 7) {
					newUrl = this.$router.resolve({
						path: "/personaldetails"
					});
				}
				window.open(newUrl.href, "_blank");

			}
		}
	};
</script>

