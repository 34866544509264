<template>
	<div class="item">
		<a :href="`/newhousedetails?id=${item.id}`" target="_blank">
		<div class="item_top">
			<div class="block">
				<!-- src="https://img1.baidu.com/it/u=4049022245,514596079&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500" -->
				<el-image class="dade_sf" style="width: 386px; height: 250px"
					:src="item.pictureUrl?this.$global.domain.img+item.pictureUrl:''">
					<template #error>
						<div class="image-slot g_center">
							<img src="@/assets/logo2.png" alt="">
						</div>
					</template>
				</el-image>
				<div class="img_title g_center">
					招商中
				</div>
			</div>
			<div class="item_bom">
				<div class="one g_center_bothSide">
					{{item.parkName}}
				</div>
				<div class="address">
					[{{item.cityName}}-{{item.areaName}}]
				</div>
				<div class="c g_center_right">
					<div class="tow g_end_bothSide">
						<div class="tow_r g_center_bothSide_v">
							<div class="g_center">
								<span class="price">{{item.avgPrice == -1?0:item.avgPrice}}</span>
							</div>
							<div class="">
								均价(元/m²)
							</div>
						</div>
						<div class="gun">

						</div>
						<div class="tow_r g_center_bothSide_v">
							<div class="g_center">
								<span class="price">{{item.buildArea == -1?0:item.buildArea}}</span>
							</div>
							<div class="">
								园区建面(㎡)
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</a>
	</div>
</template>

<script>
	import brokeritem from '@/components/brokeritem.vue'
	export default {
		components: {
			brokeritem
		},
		props: {
			item: Object
		},
		methods: {
			goDetails(id) {
				let newUrl = this.$router.resolve({
					path: "/newhousedetails?id=" + id,
					query: {
						id: id
					}
				});

				window.open(newUrl.href, "_blank");
			}
		}
	}
</script>

<style lang="scss" scoped>
	a{
		text-decoration: none;
	}
	.item {
		transition: all 0.3s;
		// transition: transform 1s;
		&:hover {
			box-shadow: 0px 2px 10px 0px rgba(71, 75, 78, 0.16);
			// transform: scale(1.001);

			.one {
				text-decoration: underline;
			}
		}

		width: 386px;
		height: 366px;
		padding: 0;
		margin: 0;
		margin-top: 24px;

		.block {
			width: 100%;
			height: 250px;
			// background: #000;
			position: relative;
			overflow: hidden;

			.el-image {
				transition: all 0.6s;
			}

			.el-image:hover {
				transform: scale(1.1);
			}

			.img_title {
				width: 57px;
				height: 25px;
				opacity: 1;
				background: #FF5E06;
				position: absolute;
				top: 19px;
				left: -3px;
				font-size: 12px;
				font-weight: 250;
				line-height: 14px;
				letter-spacing: 0px;

				color: #FFFFFF;
			}
		}

		.image-slot {
			width: 100%;
			height: 100%;
			background: #fafafa;
		}

		.item_bom {
			background: #ffffff;
			padding: 15px 8px;
			font-size: 12px;
			font-weight: 250;
			line-height: 14px;
			letter-spacing: 0px;
			color: #333333;

			.address {

				font-size: 12px;
				font-weight: 250;
				line-height: 14px;
				letter-spacing: 0px;
				color: #333333;
				text-align: left;
				margin-bottom: 12px;
			}

			.one {

				font-size: 16px;
				font-weight: 600;
				line-height: 19px;
				letter-spacing: 0px;
				color: #333333;
				text-align: left;
				padding: 0 0 12px 0;
			}

			.c {}

			.tow {
				width: 50%;
				color: #000000;
				margin-right: 21px;
				margin-bottom: 10px;

				.price {
					font-size: 16px;
					font-weight: 250;
					line-height: 19px;
					letter-spacing: 0px;
					color: #FF5E06;
				}

				.gun {
					height: 26px;
					opacity: 1;
					border: 0.5px solid #F0F4F7;
				}

				.tow_r {
					height: 38px;
				}
			}

			.tips {
				span {
					background: #F5F6FA;
					display: block;
					color: #8F98A3;
					padding: 2px 10px;
				}
			}
		}
	}
</style>