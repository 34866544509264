<template>
	<div class="item">
		
			<div class="item_top">
				<a :href="item.type == 0?`/useddetails?id=${item.id}`:`/leasedetails?id=${item.id}`" target="_blank">
				<div class="block">
					<!-- src="https://img1.baidu.com/it/u=4049022245,514596079&fm=253&fmt=auto&app=120&f=JPEG?w=889&h=500" -->
					<el-image class="dade_sf" style="width: 285px; height: 200px" lazy
						:src="(item && item.mainImage)?this.$global.domain.img+item.mainImage:''">
						<template #error>
							<div class="image-slot g_center">
								<img src="@/assets/logo2.png" alt="">
							</div>
						</template>
					</el-image>
					<div class="img_title">
						{{(item && item.propertyName) ? item.propertyName :""}}
					</div>
				</div>
				<div class="item_bom">
					<div class="one g_center_bothSide">
						<div class="g_center">
							<span class="iconfont icon-location" style="color:#aaaaab;font-size:10px"></span>
							<span>
								{{(item && item.cityName) ? item.cityName:''}}-{{(item && item.areaName) ? item.areaName:""}}
							</span>
						</div>
						<div><!-- <span>一楼｜重加工</span> --></div>
					</div>
					<div class="tow g_center_bothSide">
						<div class="g_center">
							<span class="price">{{validateminus(item.area)}}</span>
							<span>
								平
							</span>
						</div>
						<div><span
								class="price">{{validateminus(item.price)}}</span><span>{{item.type == 0?'元/平':'元/平/月起'}}
							</span></div>
					</div>
					<div class="tips g_center_left">
						<span v-if="item.changFangStructure" class="g_center">{{dict1[item.changFangStructure]}}</span>
						<span v-if="item.guardType" class="g_center">{{dict2[item.guardType]}}</span>
					</div>
					
				</div>
				</a>
				<div class="bor">
					<brokeritem :item="{avatar:item.avatar,name:item.name,phone:item.phone,year:item.year}" />
				</div>
			</div>
		
	</div>
</template>

<script>
	import brokeritem from '@/components/brokeritem.vue'
	export default {
		components: {
			brokeritem
		},
		props: {
			item: {
				type: Object,
				default: () => {
					return {}
				}
			}
		},
		data() {
			return {
				dict1: {},
				dict2: {}
			}
		},
		created() {
			this.dict1 = this.getDict('new_horse_structure')
			this.dict2 = this.getDict('guard_type')
		},
		methods: {
			// goDetails(id,type) {
			// 	// console.log(type)
			// 	let newUrl = null
			// 	if(type == 0){
			// 		 newUrl = this.$router.resolve({
			// 			path: "/useddetails?id=" + id,
			// 			query: {
			// 				id: id
			// 			}
			// 		});
			// 	}else{
			// 		newUrl = this.$router.resolve({
			// 			path: "/leasedetails?id=" + id,
			// 			query: {
			// 				id: id
			// 			}
			// 		});
			// 	}

			// 	window.open(newUrl.href, "_blank");
			// }
		}
	}
</script>

<style lang="scss" scoped>
	a {
		text-decoration: none;
	}

	.item {
		width: 285px;
		height: 366px;
		padding: 0;
		margin: 0;
		margin-top: 24px;
		background: #ffffff;

		.block {
			width: 100%;
			height: 200px;
			// background: #000;
			position: relative;
			overflow: hidden;

			.el-image {
				transition: all 0.55s;
			}

			.el-image:hover {
				transform: scale(1.1);
			}

			.img_title {
				width: 100%;
				padding: 11px 13px;
				position: absolute;
				left: 0;
				bottom: 0;

				font-size: 14px;
				font-weight: 600;
				line-height: 17px;
				letter-spacing: 0px;
				text-align: left;
				color: #FFFFFF;
				// opacity: 0.8;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
			}
		}

		.image-slot {
			width: 100%;
			height: 100%;
			background: #fafafa;
		}

		.item_bom {
			background: #ffffff;
			padding: 9px 19px;
			font-size: 12px;
			font-weight: 250;
			line-height: 14px;
			letter-spacing: 0px;
			color: #333333;

			.one {
				// padding: 9px 0 0 0 ;
			}

			.tow {
				padding: 9px 0;

				.price {
					font-size: 16px;
					font-weight: 250;
					line-height: 19px;
					letter-spacing: 0px;
					color: #FF5E06;
				}
			}

			.tips {
				min-height: 18px;
				span {
					background: #F5F6FA;
					display: block;
					margin-right: 5px;
					color: #8F98A3;
					padding: 2px 10px;
				}
			}
		}

		&:hover {

			// box-shadow: 0px 2px 10px 0px rgba(71, 75, 78, 0.16);
			// transform: scale(1.01);
			.img_title {
				// text-decoration: underline;
				color: #2a46f7;
				// font-weight: 600;

			}
		}
		.bor{
			width: 239px;
			padding: 0px 19px;
		}
	}
</style>