import request from "@/common/request";  //导入封装请求的js文件


//获取政策热度排行
export function getpolicyHeatList(params) {
  return request({
    url:'/api/policy/open/policyHeatList',  //接口路径
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取最新政策
export function getlatestPolicies(params) {
  return request({
    url:'/api/policy/open/latestPolicies', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取政策推荐
export function getpolicyRecommendations(params) {
  return request({
    url:'/api/policy/open/policyRecommendations', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取ES政策列表
export function getpolicyeslist(params) {
  return request({
    url:'/api/searchPolicy/open/getList', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取政策详情
export function getpolicyesdetail(params) {
  return request({
    url:'/api/policy/open/sysPolicyDetail', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取政策地区省

export function getpolicySearchProvince(params) {
  return request({
    url:'/api/policy/open/policySearchProvince', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}

//获取城市

export function getpolicySearchCity(params) {
  return request({
    url:'/api/policy/open/policySearchCity', 
    method: "get",  //接口方法
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, //给接口添加请求头
    params  //接口参数
  });
}